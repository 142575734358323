import {
  Button,
  Input,
  Select,
  Textarea,
  RadioGroup,
  HStack,
  Radio,
  useColorMode,
  Stack,
  Checkbox,
  CheckboxGroup,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux/es/exports";
import axios from "axios";
import {
  getFormSchema,
  getSchemaByFormId,
  getInputByFormId,
} from "../../../../redux/forms/forms-action";
import { useDispatch } from "react-redux";
import { Field } from "formik";
import { object } from "yup";
import { values } from "lodash";
import form from "../../../../apis/client/Forms/form";
import { useForm, Controller } from "react-hook-form";
import Multiselect from "multiselect-react-dropdown";
import { getData } from "../../../../redux/forms/forms-action";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

const MasterForm = ({ pageName, formId, close, edit }) => {
  // const { pageName, formId, close, edit } = props;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const sectionName = useSelector((state) => state.cms.sectionNames[pageName]);
  // let getallField = useSelector((state) => state.Forms.FormInput);
  let getFormName = useSelector((state) => state.Forms.Forms);
  // let getSchemaForm = useSelector((state) => state.Forms.FormSchemaByFormId);

  let userToken = useSelector((state) => state.AuthDetails.user.token);

  const [formFields, setForm] = useState({});
  const [change, setChange] = useState(false);
  const [getallField, setGetallField] = useState("");
  const [getSchemaForm, setGetSchemaForm] = useState("");
  const [formValidation, setFormValidation] = useState({});
console.log(getallField,'adddd');

  const {
    control,
    register,
    formState: { errors },
    setValue,
    watch,
    trigger,
  } = useForm({
    formFields,
  });

  useEffect(() => {
    setValidations();
  }, [getallField]);

  useEffect(() => {
    async function mount() {
      console.log(formId, "formID");
      if (formId) {
        let payload = {
          formId: formId,
        };

        let formDetails = await dispatch(getInputByFormId(payload));
        setGetallField(formDetails);
        let formSchema = await dispatch(getSchemaByFormId(payload));
        setGetSchemaForm(formSchema);
        console.log(getSchemaForm, formSchema, "schema 1");
      } else {
        let formSection = sectionName.filter(
          (x) => x.sectionId === "4457530739"
        );

        if (formSection.length && formSection[0].form) {
          let payload = {
            formId: formSection[0].form,
          };

          let formDetails = await dispatch(getInputByFormId(payload));
          await setGetallField(formDetails);
          let formSchema = await dispatch(getSchemaByFormId(payload));
          await setGetSchemaForm(formSchema);
          console.log(getSchemaForm, formSchema, "schema");
        }
      }
    }
    mount();
    initializeData();
    console.log(formFields, "from value");
  }, [dispatch]);
  console.log(formFields, "from value");
  //getallField[0].field.value,

  const handleClear = () => {
    if (getallField) {
      let tempForm = {};
      getallField.map((x) => {
        if (x.inputType != "button") tempForm[x.value] = "";
      });
      setForm(tempForm);
    }
  };

  const handleSubmit = async () => {
    setForm((prevState) => {
      for (let key in prevState) {
        if (typeof prevState[key] == "string") {
          prevState[key] = prevState[key].trim();
        } else {
          prevState[key] = prevState[key];
        }
      }
      return prevState;
    });
    let result = await trigger();
    if (!result) {
      return;
    }

    let payload = {
      projectId: process.env.REACT_APP_ID,
      projectName: process.env.REACT_APP_COMMUNITY_NAME,
    };
    payload = { ...formFields, ...payload };

    let key = Object.keys(payload);
    let value = Object.values(payload);
    let formData = new FormData();

    console.log(payload, "cloudpl");
    for (let i = 0; i < key.length; i++) {
      if (value[i].type && value[i].type === "files") {
        for (let j = 0; j < value[i].files.length; j++) {
          formData.append(key[i], value[i].files[j]);
        }
      } else {
        formData.append(key[i], value[i]);
      }
    }

    // let output = await axios.post(
    //   `${process.env.REACT_APP_BASE_URL}forms/create/${getSchemaForm[0].schemaName}`,
    //   formData,

    //   {
    //     headers: {
    //       "community-name": process.env.REACT_APP_COMMUNITY_NAME,
    //       authorization: `Bearer ${userToken}`,
    //     },
    //   }
    // );
    let output;
    if (getallField && getallField.length > 0 && getallField[0].isAuth) {
      output = output = await axios.post(
        `${process.env.REACT_APP_BASE_URL}forms/create/${getSchemaForm[0].schemaName}`,
        formData,

        {
          headers: {
            "community-name": process.env.REACT_APP_COMMUNITY_NAME,
            authorization: `Bearer ${userToken}`,
          },
        }
      );
    } else {
      console.log(getSchemaForm, "getSchemaForm");
      output = await axios.post(
        `${process.env.REACT_APP_BASE_URL}forms/formCreate/${getSchemaForm[0].schemaName}`,
        formData,

        {
          headers: {
            "community-name": process.env.REACT_APP_COMMUNITY_NAME,
          },
        }
      );
      // output = await axios.post(
      //   `${process.env.REACT_APP_BASE_URL}forms/formCreate/${getSchemaForm.FormSchema[0].schemaName}`,
      //   payload,
      //   {
      //     headers: {
      //       "community-name": process.env.REACT_APP_COMMUNITY_NAME,
      //       // authorization: `Bearer ${localStorage.getItem('cmsToken')}`,
      //     },
      //   }
      // );
    }

    if (output && output.data) {
      if (output.data.code === 200) {
        Swal.fire({
          title: "Thank You for Your Interest!",
          text: "We appreciate your curiosity and look forward to sharing more details with you soon.",
          icon: "success",
        });
      }

      handleClear();
      close(false);
    }
  };

  const handleChange = async (e, field, item) => {
    let fields;

    if (item === "file") {
      fields = { ...formFields, [field]: e.target.files[0] };
    } else if (item === "files") {
      fields = {
        ...formFields,
        [field]: { type: "files", files: e.target.files },
      };
    } else if (item === "radio") {
      fields = { ...formFields, [field]: e };
    } else {
      fields = { ...formFields, [field]: e.target.value };
      // console.log('ENtered else');
    }

    setForm(fields);
  };

  const initializeData = async () => {
    if (edit && Object.keys(edit).length) {
      let payload = {
        projectId: process.env.REACT_APP_ID,
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        _id: edit._id,
      };
      let formData = formFields;
      let keys = Object.keys(formData);
      let data = await dispatch(getData(edit.form, payload));
      console.log(keys, "edit detail");
      if (data && data.result && data.result.length) {
        for (let i = 0; i < keys.length; i++) {
          formData[keys[i]] = data.result[0][keys[i]];
        }

        setForm(formData);
      }
    }
  };

  const setValidations = async () => {
    console.log(getallField, "fields");
    let tempValidation = {};
    if (getallField.length) {
      getallField.forEach((field) => {
        if (field.inputType != "button") {
          let options = {};
          if (field?.mandatoryField)
            options["required"] = {
              value: field?.mandatoryField,
              message: `${field.value} is required`,
            };

          if (field?.inputType == "imagefile")
            options["validate"] = {
              checkFileType: (value) => {
                const file = value[0];
                return (
                  (file && file.type.startsWith("image/")) ||
                  "Only image files are allowed"
                );
              },
            };

          if (field?.inputType === "email")
            options["pattern"] = {
              value: /^\S+@\S+$/i,
              message: `Email is invalid`,
            };

          if (field?.maxLength)
            options["maxLength"] = {
              value: field?.maxLength,
              message: `${field.value} must contain maximum ${field?.maxLength} characters`,
            };

          if (field?.minLength)
            options["minLength"] = {
              value: field?.minLength,
              message: `${field.value} must contain minimum ${field?.minLength} characters`,
            };

          if (field?.pattern)
            options["pattern"] = {
              value: field?.pattern,
              message: `Invalid field`,
            };

          if (["text", "textarea"].includes(field?.type)) {
            options["setValueAs"] = (value) => value.trim();
          }

          tempValidation[field.value] = {
            ...register(field.value, options),
          };
        }
      });
      console.log(tempValidation, "fields val");
      setFormValidation(tempValidation);
    }
  };

  return (
    <section>
      <div>
        <div className="lg:w-5/6 md:w-5/6 m-auto lg:grid md:grid grid-cols-1 gap-4 ">
          {getallField
            ? getallField.map((item, index) => {
                return (
                  <div className="lg:pb-0 md:pb-0 pb-3 ">
                    {/* {!["button", "notessystem"].includes(item.inputType) && (
                      <p
                        id="1437369070"
                        className=" lg:pb-2 md:pb-2 pb-1 clr-191E22"
                      >
                        {item.label}
                        <sup class="pl-1 clr-red fsize10">*</sup>
                      </p>
                    )} */}
                    {item.inputType == "input" && (
                      <>
                        <Input
                          borderRadius={10}
                          height="4rem"
                          border={0}
                          className="custShadow1  roundedfull p-2 inp-bdr w-full bg-white cust-textColor theme-bg themeShadow"
                          placeholder={item.label}
                          value={formFields[item.value]}
                          {...formValidation[item.value]}
                          onChange={(e) => {
                            handleChange(e, item.value);
                          }}
                          id={`${item.value}-field`}
                        />
                        {errors[item.value] && (
                          <span
                            className="fsize12 text-danger"
                            id={`${item.value}-validation-error`}
                          >
                            {errors[item.value]?.message}
                          </span>
                        )}
                      </>
                    )}
                    {item.inputType == "multiline" && (
                      <>
                        <Textarea
                          className="p-2 inp-bdr w-full"
                          placeholder={item.label}
                          value={formFields[item.value]}
                          {...formValidation[item.value]}
                          onChange={(e) => {
                            handleChange(e, item.value);
                          }}
                          id={`${item.value}-field`}
                        />
                        {errors[item.value] && (
                          <span
                            className="fsize12 text-danger"
                            id={`${item.value}-validation-error`}
                          >
                            {errors[item.value]?.message}
                          </span>
                        )}
                      </>
                    )}
                    {item.inputType == "phone" && (
                      <>
                        <Input
                          borderRadius={10}
                          height="4rem"
                          border={0}
                          className="custShadow1  roundedfull p-2 inp-bdr w-full bg-white cust-textColor theme-bg themeShadow"
                          placeholder={item.label}
                          value={formFields[item.value]}
                          {...formValidation[item.value]}
                          onChange={(e) => {
                            handleChange(e, item.value);
                          }}
                          id={`${item.value}-field`}
                        />
                        {errors[item.value] && (
                          <span
                            className="fsize12 text-danger"
                            id={`${item.value}-validation-error`}
                          >
                            {errors[item.value]?.message}
                          </span>
                        )}
                      </>
                    )}
                    {item.inputType == "email" && (
                      <>
                        <Input
                          type="email"
                          borderRadius={10}
                          height="4rem"
                          border={0}
                          className="custShadow1  roundedfull p-2 inp-bdr w-full bg-white cust-textColor theme-bg themeShadow"
                          placeholder={item.label}
                          value={formFields[item.value]}
                          {...formValidation[item.value]}
                          onChange={(e) => {
                            handleChange(e, item.value);
                          }}
                          id={`${item.value}-field`}
                        />
                        {errors[item.value] && (
                          <span
                            className="fsize12 text-danger"
                            id={`${item.value}-validation-error`}
                          >
                            {errors[item.value]?.message}
                          </span>
                        )}
                      </>
                    )}
                    {item.inputType == "url" && (
                      <>
                        <Input
                          name={formFields[item.value]}
                          className="p-2 inp-bdr w-full"
                          placeholder={item.placeholder}
                          value={formFields[item.value]}
                          {...formValidation[item.value]}
                          onChange={(e) => {
                            handleChange(e, item.value);
                          }}
                          id={`${item.value}-field`}
                        />
                        {errors[item.value] && (
                          <span
                            className="fsize12 text-danger"
                            id={`${item.value}-validation-error`}
                          >
                            {errors[item.value]?.message}
                          </span>
                        )}
                      </>
                    )}
                    {item.inputType === "singleselect" && (
                      <>
                        <Select
                          placeholder={item.placeholder}
                          value={formFields[item.value]}
                          className="fsize14"
                          {...formValidation[item.value]}
                          onChange={(e) => {
                            handleChange(e, item.value);
                          }}
                          id={`${item.value}-field`}
                        >
                          {item.enum.map((i) => {
                            return <option value={i}>{i}</option>;
                          })}
                        </Select>
                        {errors[item.value] && (
                          <span
                            className="fsize12 text-danger"
                            id={`${item.value}-validation-error`}
                          >
                            {errors[item.value]?.message}
                          </span>
                        )}
                      </>
                    )}
                    {item.inputType === "radiobutton" && (
                      <>
                        <Controller
                          name={item.value}
                          control={control}
                          rules={{ ...formValidation[item.value] }}
                          render={() => (
                            <RadioGroup
                              {...formValidation[item.value]}
                              onChange={(e) =>
                                handleChange(e, item.value, "radio")
                              }
                              value={formFields[item.value]}
                              id={`${item.value}-field`}
                            >
                              <HStack spacing="24px">
                                {item.enum.map((e) => {
                                  return <Radio value={e}>{e}</Radio>;
                                })}
                              </HStack>
                            </RadioGroup>
                          )}
                        />

                        {errors[item.value] && (
                          <span
                            className="fsize12 text-danger"
                            id={`${item.value}-validation-error`}
                          >
                            {errors[item.value]?.message}
                          </span>
                        )}
                      </>
                    )}
                    {item.inputType === "imagefile" && (
                      <>
                        <input
                          type="file"
                          className="p-2 inp-bdr w-full"
                          placeholder={item.placeholder}
                          {...formValidation[item.value]}
                          onChange={(e) => {
                            handleChange(e, item.value, "file");
                          }}
                          id={`${item.value}-field`}
                        />
                        {errors[item.value] && (
                          <span
                            className="fsize12 text-danger"
                            id={`${item.value}-validation-error`}
                          >
                            {errors[item.value]?.message}
                          </span>
                        )}
                      </>
                    )}
                    {item.inputType === "file" && (
                      <>
                        <input
                          type="file"
                          className="p-2 inp-bdr w-full"
                          placeholder={item.placeholder}
                          {...formValidation[item.value]}
                          onChange={(e) => {
                            handleChange(e, item.value, "file");
                          }}
                          id={`${item.value}-field`}
                        />
                        {errors[item.value] && (
                          <span
                            className="fsize12 text-danger"
                            id={`${item.value}-validation-error`}
                          >
                            {errors[item.value]?.message}
                          </span>
                        )}
                      </>
                    )}

                    {item.inputType === "profilepic" && (
                      <>
                        <input
                          type="file"
                          className="p-2 inp-bdr w-full"
                          placeholder={item.placeholder}
                          {...formValidation[item.value]}
                          onChange={(e) => {
                            handleChange(e, item.value, "file");
                          }}
                          id={`${item.value}-field`}
                        />
                        {errors[item.value] && (
                          <span
                            className="fsize12 text-danger"
                            id={`${item.value}-validation-error`}
                          >
                            {errors[item.value]?.message}
                          </span>
                        )}
                      </>
                    )}

                    {item.inputType === "multipleimagefile" && (
                      <>
                        <input
                          type="file"
                          multiple
                          className="p-2 inp-bdr w-full"
                          placeholder={item.placeholder}
                          {...formValidation[item.value]}
                          onChange={(e) => {
                            handleChange(e, item.value, "files");
                          }}
                          id={`${item.value}-field`}
                        />
                        {errors[item.value] && (
                          <span
                            className="fsize12 text-danger"
                            id={`${item.value}-validation-error`}
                          >
                            {errors[item.value]?.message}
                          </span>
                        )}
                      </>
                    )}

                    {item.inputType === "multiselect" && (
                      <>
                        <Controller
                          name={item.value}
                          control={control}
                          rules={{ ...formValidation[item.value] }}
                          render={() => (
                            <Multiselect
                              className="w-full"
                              options={item.enum.map((select, index) => {
                                return { name: select, id: index };
                              })} // Options to display in the dropdown
                              selectedValues={formFields[item.value]} // Preselected value to persist in dropdown
                              onSelect={(e) =>
                                handleChange(e, item.value, "multiselect")
                              } // Function will trigger on select event
                              onRemove={(e) =>
                                handleChange(e, item.value, "multiselect")
                              } // Function will trigger on remove event
                              displayValue="name" // Property name to display in the dropdown options
                              id={`${item.value}-field`}
                            />
                          )}
                        />
                        {errors[item.value] && (
                          <span
                            className="fsize12 text-danger"
                            id={`${item.value}-validation-error`}
                          >
                            {errors[item.value]?.message}
                          </span>
                        )}
                      </>
                    )}

                    {item.inputType === "date" && (
                      <>
                        <Input
                          type="date"
                          className="p-2 inp-bdr w-full"
                          placeholder={item.placeholder}
                          {...formValidation[item.value]}
                          onChange={(e) => {
                            handleChange(e, item.value, "files");
                          }}
                          id={`${item.value}-field`}
                        />
                        {errors[item.value] && (
                          <span
                            className="fsize12 text-danger"
                            id={`${item.value}-validation-error`}
                          >
                            {errors[item.value]?.message}
                          </span>
                        )}
                      </>
                    )}

                    {item.inputType === "date&time" && (
                      <>
                        <Input
                          type="datetime-local"
                          className="p-2 inp-bdr w-full"
                          placeholder={item.placeholder}
                          {...formValidation[item.value]}
                          onChange={(e) => {
                            handleChange(e, item.value, "files");
                          }}
                          id={`${item.value}-field`}
                        />
                        {errors[item.value] && (
                          <span
                            className="fsize12 text-danger"
                            id={`${item.value}-validation-error`}
                          >
                            {errors[item.value]?.message}
                          </span>
                        )}
                      </>
                    )}

                    {item.inputType === "checkbox" && (
                      <>
                        <Controller
                          name={item.value}
                          control={control}
                          rules={{ ...formValidation[item.value] }}
                          render={() => (
                            <CheckboxGroup
                              defaultValue={[]}
                              onChange={(e) => {
                                handleChange(e, item.value, "checkbox");
                              }}
                              id={`${item.value}-field`}
                            >
                              <Stack
                                spacing={[1, 10]}
                                direction={["column", "row"]}
                              >
                                {item.enum.map((check) => {
                                  return (
                                    <Checkbox value={check}>{check}</Checkbox>
                                  );
                                })}
                              </Stack>
                            </CheckboxGroup>
                          )}
                        />

                        {errors[item.value] && (
                          <span
                            className="fsize12 text-danger"
                            id={`${item.value}-validation-error`}
                          >
                            {errors[item.value]?.message}
                          </span>
                        )}

                        {/* <CheckboxGroup defaultValue="select">
                              <Stack
                                spacing={[1, 7]}
                                direction={['column', 'row']}
                              >
                                <Checkbox>check</Checkbox>
                                <Checkbox>check</Checkbox>
                                <Checkbox>check</Checkbox>
                              </Stack>
                            </CheckboxGroup> */}
                      </>
                    )}
                    {/* {item.inputType == 'button' && (
                          <Input
                            type="button"
                            className="p-2 inp-bdr w-full"
                            placeholder={item.placeholder}
                            value={item.value}
                            maxlength={item.maxLength}
                            minlength={item.minLength}
                            id={`${item.value}-field`}
                          />
                        )} */}
                    {item.inputType === "time" && (
                      <>
                        <Input
                          type="time"
                          className="p-2 inp-bdr w-full"
                          placeholder={item.placeholder}
                          {...formValidation[item.value]}
                          onChange={(e) => {
                            handleChange(e, item.value, "files");
                          }}
                          id={`${item.value}-field`}
                        />
                        {errors[item.value] && (
                          <span
                            className="fsize12 text-danger"
                            id={`${item.value}-validation-error`}
                          >
                            {errors[item.value]?.message}
                          </span>
                        )}
                      </>
                    )}
                    {item.inputType === "mappedTable" && (
                      <>
                        <Select
                          placeholder={item.placeholder}
                          value={formFields[item.value]}
                          className="fsize14"
                          {...formValidation[item.value]}
                          onChange={(e) => {
                            handleChange(e, item.value);
                          }}
                        >
                          {item.data &&
                            item.data.map((i) => {
                              return <option value={i._id}>{i.value}</option>;
                            })}
                        </Select>
                        {errors[item.value] && (
                          <span
                            className="fsize12 text-danger"
                            id={`${item.value}-validation-error`}
                          >
                            {errors[item.value]?.message}
                          </span>
                        )}
                      </>
                    )}

                    {/* {item.inputType == 'notessystem' && (
                          <>
                            <Textarea
                              className="p-2 inp-bdr w-full"
                              placeholder={item.placeholder}
                              {...formValidation[item.value]}
                              onChange={e => {
                                handleChange(e, item.value);
                              }}
                              id={`${item.value}-field`}
                            />
                            {errors[item.value] && (
                              <span
                                className="fsize12 text-danger"
                                id={`${item.value}-validation-error`}
                              >
                                {errors[item.value]?.message}
                              </span>
                            )}
                          </>
                        )} */}
                  </div>
                );
              })
            : ""}
        </div>
    
                <div className=" text-center w-full lg:w-5/6 md:w-5/6 mx-auto">
               
                    <Button
                      onClick={() => handleSubmit()}
                      colorScheme="primary"
                      className=" font-semibold roundedfull w-full  bg-da8f2c py-7 px-4 lg:mt-6 md:mt-6 mt-2"
                    >
                      Submit
                    </Button>
               
                </div>
  
        {/* {getallField
          ? getallField.map((item) => {
              return (
                <div className=" text-center w-full lg:w-5/6 md:w-5/6 mx-auto">
                  {item.inputType == "button" && (
                    <Button
                      onClick={() => handleSubmit()}
                      colorScheme="primary"
                      className=" font-semibold roundedfull w-full  bg-da8f2c py-7 px-4 lg:mt-6 md:mt-6 mt-2"
                    >
                      <span> {item.label}</span>
                    </Button>
                  )}
                </div>
              );
            })
          : "no-data"} */}
      </div>
    </section>
  );
};

export default MasterForm;
