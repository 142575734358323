import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux/es/exports";
import { Card, Image, useColorMode } from "@chakra-ui/react";
import UserEffect from "./usereeffect/UserEffect";
import { handleScroll } from "./fadeeffect/FadeEffect";

const AboutSection4 = ({ pageName }) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const { transform, handleMouseMove, handleMouseLeave } = UserEffect();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  useEffect(() => {
    const cleanp1 = handleScroll(elementRef, setIsVisible);

    return () => {
      cleanp1();
    };
  }, []);
  return (
    <section className="container mx-auto mx-auto md:px-8 lg:px-12 px-5 lg:py-16 md:py-16 py-8" id="program">
      <div className="w-full lg:flex  gap-8">
        <div className="lg:w-1/2  w-full">
          <div className="w-full h-full">
            <div id="7347724826" className="dynamicStyle2">
              <Image
                src={data ? getImage(data["7347724826"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                alt="about"
                className="object-contain w-full h-500px md-h-350px sm-h250px br-28px "
                style={{ transform: transform }}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
              />
            </div>
          </div>
        </div>
        <div className="lg:w-1/2 lg:mt-0 md:mt-8 mt-6 w-full">
          <p
            id="4101440900"
            className={
              colorMode === "light"
                ? "dynamicStyle lg:pt-0 md:pt-0 pt-4 uppercase fsize16 md-fsize16 sm-fsize14 font-medium text-primary "
                : "dynamicStyle lg:pt-0 md:pt-0 pt-4 uppercase fsize16 md-fsize16 sm-fsize14 font-medium text-white"
            }
          >
            {data ? data["4101440900"] : "Lorem Ipsum"}
          </p>
          <h2
            id="4160252202"
            className="dynamicStyle text-black cust-textColor fsize34 md-fsize24 sm-fsize20 font-semibold lg:leading-12 md:leading-9 lg:mb-2 md:mb-2 mb-2  sm-lh-32px"
          >
            {data ? data["4160252202"] : "Lorem Ipsum"}
          </h2>
          <p
            id="8330960685"
            dangerouslySetInnerHTML={{
              __html: data
                ? data["8330960685"]
                : " Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            }}
            className="dynamicStyle textColor-light fsize14 sm-fsize14 text-black "
          ></p>

          <div className="grid lg:grid-cols-1 md:grid-cols-2 grid-cols-1 lg:gap-0 gap-3 mt-2">
            <div
              borderRadius={0}
              className={
                colorMode === "light"
                  ? "flex lg:items-center lg:gap-0 md:gap-0 gap-4 items-start border-dash-bottom-light-gray box-shadow-none lg:py-6 lg:px-5 md:py-4 md:px-4 py-3 px-1 themeShadow theme-bg"
                  : "flex lg:items-center lg:gap-0 md:gap-0 gap-4 items-start border-dash-bottom-light-gray box-shadow-none lg:py-6 lg:px-5 md:py-4 md:px-4 py-3 px-1 themeShadow theme-bg"
              }
            >
              <div id="6740048928" className="dynamicStyle2 lg:w-1/5 md:w-3/12 w-2/12">
                <Image
                  src={data ? getImage(data["6740048928"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                  className="lg:w-20 lg:h-20 md:w-16 md:h-16 w-12 h-12 object-contain"
                />
              </div>
              <div className="lg:w-4/5 md:w-9/12 w-10/12">
                <h2
                  id="3124594170"
                  className="dynamicStyle fsize18 sm-fsize16 font-semibold cust-textColor"
                >
                  {data ? data["3124594170"] : "When We Started"}
                </h2>
                <p
                  id="9329055318"
                  dangerouslySetInnerHTML={{
                    __html: data
                      ? data["9329055318"]
                      : " Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  }}
                  className="dynamicStyle cust-textColor fsize14 sm-fsize12 text-black cust-textColor textColor-light mt-1"
                ></p>
              </div>
            </div>
            <div
              borderRadius={0}
              className={
                colorMode === "light"
                  ? "flex lg:items-center lg:gap-0 md:gap-0 gap-4 items-start border-dash-bottom-light-gray box-shadow-none lg:py-6 lg:px-5 md:py-4 md:px-4 py-3 px-1 themeShadow theme-bg"
                  : "flex lg:items-center lg:gap-0 md:gap-0 gap-4 items-start border-dash-bottom-light-gray box-shadow-none lg:py-6 lg:px-5 md:py-4 md:px-4 py-3 px-1 themeShadow theme-bg"
              }
            >
              <div id="2768721663" className="dynamicStyle2 lg:w-1/5 md:w-3/12 w-2/12">
                <Image
                  src={data ? getImage(data["2768721663"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                  className="lg:w-20 lg:h-20 md:w-16 md:h-16 w-12 h-12 object-contain"
                />
              </div>
              <div className="lg:w-4/5 md:w-9/12 w-10/12">
                <h2
                  id="9613322167"
                  className="dynamicStyle fsize18 sm-fsize16 font-semibold cust-textColor"
                >
                  {data ? data["9613322167"] : "Lorem Ipsum"}
                </h2>
                <p
                  id="0112669977"
                  dangerouslySetInnerHTML={{
                    __html: data
                      ? data["0112669977"]
                      : " Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  }}
                  className="dynamicStyle fsize14 sm-fsize12 text-black cust-textColor textColor-light mt-1"
                ></p>
              </div>
            </div>
            <div
              borderRadius={0}
              className={
                colorMode === "light"
                  ? "flex lg:items-center lg:gap-0 md:gap-0 gap-4 items-start border-dash-bottom-light-gray box-shadow-none lg:py-6 lg:px-5 md:py-4 md:px-4 py-3 px-1 themeShadow theme-bg"
                  : "flex lg:items-center lg:gap-0 md:gap-0 gap-4 items-start border-dash-bottom-light-gray box-shadow-none lg:py-6 lg:px-5 md:py-4 md:px-4 py-3 px-1 themeShadow theme-bg"
              }
            >
              <div id="1555831072" className="dynamicStyle2 lg:w-1/5 md:w-3/12 w-2/12">
                <Image
                  src={data ? getImage(data["1555831072"]) : ""}
                  fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}Quarkly/default/1285952.jpg`}
                  alt="img"
                  className="lg:w-20 lg:h-20 md:w-16 md:h-16 w-12 h-12 object-contain"
                />
              </div>
              <div className="lg:w-4/5 md:w-9/12 w-10/12">
                <h2
                  id="3145495928"
                  className="dynamicStyle fsize18 sm-fsize16 font-semibold cust-textColor"
                >
                  {data ? data["3145495928"] : "Lorem Ipsum"}
                </h2>
                <p
                  id="3975286171"
                  dangerouslySetInnerHTML={{
                    __html: data
                      ? data["3975286171"]
                      : " Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                  }}
                  className="dynamicStyle fsize14 sm-fsize12 text-black textColor-light"
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection4;
